import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../components/index.scss'

import PortfolioLink from "../components/portfolio-link";
import TalkLink from "../components/talk-link";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <section itemProp="description" className="intro-text">
      <p>Wearer of many hats, former designer, full-stack developer,
        teacher. Now: iOS Developer.</p>
      <p>Swift and JavaScript are the tools of the trade
        for now, who knows <strong>what's in store for tomorrow?</strong></p>
    </section>

    <h2 className="project-list-title">Latest Projects</h2>
    <ul className="project-list">
      <PortfolioLink url="/connect-sense/" name="ConnectSense"/>
      <PortfolioLink url="/u-by-moen/" name="U by Moen"/>
      <PortfolioLink url="/basement-guardian/" name="Basement Guardian"/>
      <PortfolioLink url="/steals/" name="Steals"/>
      <PortfolioLink url="/backcountry/" name="Backcountry"/>
    </ul>

    <h2 className="talk-list-title">Latest Talks</h2>
    <ul className="talk-list">
      <TalkLink name={"¿Cómo viajar a Indiana por $200?"} url={'como-viajar-a-indiana-por-200'}/>
      <TalkLink name={"Accessibility in iOS"} url={'accessibility-in-ios'}/>
      <TalkLink name={"XCUITesting: lo bueno, lo malo y lo feo"} url={'xcui-testing'}/>
      <TalkLink name={"this is how we do it"} url={'this-is-how-we-do-it'}/>
    </ul>
  </Layout>
);

export default IndexPage
