import { Link } from "gatsby"
import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image"

import "./portfolio-link.scss"

const PortfolioLink = ({name, url}) => {
  const icons = useStaticQuery(graphql`
    query {
      connectSense: file(relativePath: { eq: "portfolio/connect-sense/icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      uByMoen: file(relativePath: { eq: "portfolio/u-by-moen/icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      basementGuardian: file(relativePath: { eq: "portfolio/basement-guardian/icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      steals: file(relativePath: { eq: "portfolio/steals/icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      backcountry: file(relativePath: { eq: "portfolio/backcountry/icon2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      turcuas: file(relativePath: { eq: "portfolio/turcuas/icon.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      theGameChef: file(relativePath: { eq: "portfolio/the-game-chef/icon.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  let path;
  switch (name) {
    case "ConnectSense":
      path = 'connectSense';
      break;
    case "U by Moen":
      path = 'uByMoen';
      break;
    case "Steals":
      path = 'steals';
      break;
    case "Basement Guardian":
      path = 'basementGuardian';
      break;
    case "The Game Chef":
      path = 'theGameChef';
      break;
    case "Turcuas":
      path = 'turcuas';
      break;
    case "Backcountry":
      path = 'backcountry';
      break;
    default:
      path = 'connectSense';
  }

  return <li className="portfolio-link">
    <Link to={url}>
      <Img fluid={icons[path].childImageSharp.fluid} alt={name} className={'thumb'}/>
      <span hidden>{name}</span>
    </Link>
  </li>
};

export default PortfolioLink
