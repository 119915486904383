import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

import "./portfolio-item.scss"

const PortfolioItem = ({name, url, summary, stack, frameworks, year, imageQuery, role}) => {
  return <div className="portfolio-item">
    <h2 className="project-name">
      <Img fluid={imageQuery.icon.childImageSharp.fluid} alt={name} className="thumb"/>
      {name}
    </h2>

    <div className="project-details">
      {imageQuery.hero01 && <Img fluid={imageQuery.hero01.childImageSharp.fluid} alt={name} className="project-hero"/>}
      {imageQuery.hero02 && <Img fluid={imageQuery.hero02.childImageSharp.fluid} alt={name} className="project-hero second"/>}
      {imageQuery.hero03 && <Img fluid={imageQuery.hero03.childImageSharp.fluid} alt={name} className="project-hero last"/>}
      <section>
        <h3 className="project-details-title">Project Details</h3>
        <ul className="project-detail-list">
          <li>Role: {role}</li>
          <li>Stack: {stack}</li>
          <li>Frameworks: {frameworks}</li>
          <li>Year: {year}</li>
          <li>URL: <a href={url} target="_blank">{name}</a></li>
        </ul>
        <p className="project-summary">{summary}</p>
      </section>
    </div>
  </div>
};

PortfolioItem.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  stack: PropTypes.string,
  summary: PropTypes.string,
  url: PropTypes.string,
  year: PropTypes.number,
};

PortfolioItem.defaultProps = {
  frameworks: 'HomeKit, fastlane',
  name: `ConnectSense`,
  role: 'iOS Developer',
  stack: `Swift`,
  summary: `Lorem ipsum sit dolor amet`,
  url: `https://apps.apple.com/us/app/connectsense/id924559441`,
  year: 2018,
};

export default PortfolioItem
