import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Talk from "../components/talk";

import '../components/index.scss'
import HomeLink from "../components/home-link";

const AccessibilityInIosPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "talks/accessibility-in-ios.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title ="Accessibility in iOS";

  return <Layout>
    <SEO title={title}/>
    <HomeLink/>
    <Talk
      imageQuery={imageQuery}
      name={title}
      year={2017}
      summary="After receiving a workshop in Apple's headquarters in Accessibility, this was a premier for the rest of
      the iOS team on basic Accessibility practices in iOS."
    />
  </Layout>
};

export default AccessibilityInIosPage
