import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Talk from "../components/talk";

import '../components/index.scss'
import HomeLink from "../components/home-link";

const XCUITestingPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "talks/xcui-testing2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title ="XCUI Testing: lo bueno, lo malo y lo feo";

  return <Layout>
    <SEO title={title}/>
    <HomeLink/>
    <Talk
      imageQuery={imageQuery}
      name={title}
      year={2016}
      summary="I gave this talk in our local CocoaHeads chapter, it was a gentle introduction into XCUITesting.
      The new, at the time, UI testing framework developed by Apple."
    />
  </Layout>
};

export default XCUITestingPage
