import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PortfolioItem from "../components/portfolio-item";

import '../components/index.scss'
import HomeLink from "../components/home-link";

const BackcountryPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "portfolio/backcountry/icon2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero01: file(relativePath: { eq: "portfolio/backcountry/01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero02: file(relativePath: { eq: "portfolio/backcountry/02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero03: file(relativePath: { eq: "portfolio/backcountry/03.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Layout>
    <SEO title="U by Moen"/>
    <HomeLink/>
    <PortfolioItem
      name="Backcountry"
      year="2015"
      stack="JavaScript"
      frameworks="NodeJS, Express, mongoDB, Appcelerator Titanium"
      imageQuery={imageQuery}
      role={'JavaScript Developer'}
      url="https://apps.apple.com/us/app/backcountry-outdoor-gear/id450920999"
      summary="Worked on the team that launched the first Universal iOS Application."
    />
  </Layout>
};

export default BackcountryPage
