import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PortfolioItem from "../components/portfolio-item";

import '../components/index.scss'
import HomeLink from "../components/home-link";

const StealsPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "portfolio/steals/icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero01: file(relativePath: { eq: "portfolio/steals/01.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero02: file(relativePath: { eq: "portfolio/steals/02.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero03: file(relativePath: { eq: "portfolio/steals/03.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Layout>
    <SEO title="Steals"/>
    <HomeLink/>
    <PortfolioItem
      frameworks={'Zend, Doctrine'}
      imageQuery={imageQuery}
      name="Steals"
      role={'iOS & PHP Developer'}
      stack={'Swift, PHP'}
      year={2015}
      url={'https://apps.apple.com/us/app/steals-com-daily-deals-on-brands-you-love/id407564886#?platform=iphone'}
      summary={'Stepping into native iOS Development, the revamped Steals.com App was developed using Swift and quickly rose as the fifth source of traffic for the company.'}
    />
  </Layout>
};

export default StealsPage
