import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Talk from "../components/talk";

import '../components/index.scss'
import HomeLink from "../components/home-link";

const XCUITestingPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "talks/como-viajar-a-indiana-por-200.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title ="¿Cómo viajar a Indiana por $200?";

  return <Layout>
    <SEO title={title}/>
    <HomeLink/>
    <Talk
      imageQuery={imageQuery}
      name={title}
      year={2018}
      recordingURL="https://www.youtube.com/watch?v=Bdp_EKbvh7A"
      summary="I've given this talk a few times, the last time in Drupal Camp 2018, the biggest web conference in Costa
      Rica. This my life, my experiences, the good ones, but specially the bad ones."
    />
  </Layout>
};

export default XCUITestingPage
