import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./footer.scss"

export default () => {
  return (
    <footer>
      <navigation className="sm-wrap">
        <a itemProp="url" className="sm-item linkedin js-linkedin" href="http://www.linkedin.com/in/leopic"
           target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <a itemProp="url" className="sm-item github js-github" href="https://github.com/leopic"
           target="_blank" rel="noopener noreferrer">Github</a>
        <a itemProp="url" className="sm-item twitter js-twitter" href="http://twitter.com/leopyc"
           target="_blank" rel="noopener noreferrer">@leopyc</a>
        <a itemProp="url" className="sm-item stackoverflow" href="http://stackoverflow.com/users/622356/leopic"
           target="_blank" rel="noopener noreferrer">StackOverflow</a>
        <a itemProp="url" className="sm-item instagram" href="http://instagram.com/leopic"
           target="_blank" rel="noopener noreferrer">Instagram</a>
        <a itemProp="url" className="sm-item meetup" href="https://www.meetup.com/members/12425045/"
           target="_blank" rel="noopener noreferrer">Meetup</a>
      </navigation>
      <small style={{margin: '1rem 0', display: 'block'}}>
        Cartago, Costa Rica. {new Date().getFullYear()} - Leo Picado.
      </small>
    </footer>
  )
};
