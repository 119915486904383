import { Link } from "gatsby"
import React from "react"

import "./header.scss"

const Header = () => (
  <header>
    <Link to="/">
      <meta itemProp="name" content="Leo Picado"/>
      <meta itemProp="additionalName" content="leopic"/>
      <h1 className="name-text">Leo<strong>Picado</strong></h1>
      <h2 className="position-text" itemProp="jobTitle">Software Engineer</h2>
    </Link>
  </header>
);

export default Header
