import { Link } from "gatsby"
import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image"

import "./talk-link.scss"

const TalkLink = ({name, url}) => {
  const icons = useStaticQuery(graphql`
    query {
      comoViajarAIndianaPor200: file(relativePath: { eq: "talks/como-viajar-a-indiana-por-200.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      accessibilityInIos: file(relativePath: { eq: "talks/accessibility-in-ios.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      xcuiTesting: file(relativePath: { eq: "talks/xcui-testing2.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      thisIsHowWeDoIt: file(relativePath: { eq: "talks/this-is-how-we-do-it.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  let path;
  switch (url) {
    case "xcui-testing":
      path = 'xcuiTesting';
      break;
    case "this-is-how-we-do-it":
      path = 'thisIsHowWeDoIt';
      break;
    case 'como-viajar-a-indiana-por-200':
      path = 'comoViajarAIndianaPor200';
      break;
    case 'accessibility-in-ios':
      path = 'accessibilityInIos';
      break;
    default:
      path = 'xcuiTesting';
  }

  return <li className="talk-link">
    <Link to={url}>
      <Img fluid={icons[path].childImageSharp.fixed} alt={name} className={'thumb'}/>
      <span>{name}</span>
    </Link>
  </li>
};

export default TalkLink
